.card {
  position: relative;
}

.card:hover .cover {
  background-color: rgb(0 0 0 / 10%);
}

.card-title {
  margin-top: 0 !important;
}

.card-text {
  font-size: 0.9em;
}

.card-img-top {
  cursor: pointer;
}

.card-img-top > object {
  width: 100%;
}

.cover {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
  position: absolute;
  background-color: rgb(0 0 0 / 50%);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

embed::-webkit-scrollbar {
  display: none;
}

.react-pdf__message {
  display: flex;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}

.react-pdf__Page__annotations {
  display: none;
}

.uc-words {
  font-weight: 400;
  text-transform: lowercase;
}
.uc-words:first-letter {
  text-transform: uppercase;
}

.btn-link {
  color: #007bff !important;
  text-decoration: underline;
  margin: 0;
}
